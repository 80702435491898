<template>
  <div class="logo" v-if="$store.getters.getSiteInfo">
    <img v-show="$store.getters.getSiteInfo" :src="$store.getters.getSiteInfo.logal" :alt="$store.getters.getSiteInfo.name"/>
  </div>
</template>

<script>
export default {
  props: {
    font: { default: 'font-size: 34px;' }
  }
}
</script>

<style scoped src="styles/components/navbarlogo.css">
</style>
