<template>
  <div class="nav-bar">
    <div class="container">
      <div class="container-full header">
        <slot name="left"><nav-bar-left /></slot>
        <slot name="right"><nav-bar-user :style="rightShow" /></slot>
      </div>
    </div>
  </div>
</template>

<script>
import NavBarUser from 'components/navbar/NavBarUser'
import NavBarLeft from 'components/navbar/NavBarLeft'
import { getTopNotice } from 'network/api'
export default {
  components: {
    NavBarUser,
    NavBarLeft
  },
  props: {
    rightShow: { default: 'display: block;' }
  },
  data () {
    return {
      // textArr: [],
      number: 0,
      pathname: '',
      show: false
    }
  },
  created () {
    // const pathname = window.location.pathname
    // console.log(pathname)
    // this.getTopNotice()
  },
  mounted () {
    // this.startMove()
  },
  methods: {
  /*
    startMove () {
      const then = this
      // eslint-disable-next-line
      let timer = setInterval(() => {
        if (then.number < then.textArr.length - 1) {
          then.number += 1
        } else {
          then.number = 0
        }
      }, 3000) // 滚动不需要停顿则将2000改成动画持续时间
    },
    getTopNotice () {
      const then = this
      getTopNotice().then((res) => {
        if (res.code === 0) {
          return
        }
        if (res.code === 1) {
          res.data.forEach(function (item) {
            then.textArr.push(item.title)
          })
        }
      })
    }
    */
  }
}
</script>

<style scoped src="styles/components/navbar.css">
.slide-enter-active,
.slide-leave-active {
  transition: all 0.5s linear;
}
.slide-leave-to {
  transform: translateY(-20px);
}
.slide-enter {
  transform: translateY(20px);
}
.text2 {
  background: coral;
  position: absolute;
  text-align: center;
  justify-content: center;
}
.text-container {
  margin-left: 1%;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

</style>
