<template>
  <div>
    <!-- 账号登录 -->
    <el-form ref="thirdForm" v-if="numOrSms" :model="formData" :rules="rules">
      <el-form-item prop="account">
        <el-input v-model="formData.account" prefix-icon="iconfont icon-phone" placeholder="手机号码/用户名"></el-input>
      </el-form-item>
      <!-- <el-form-item prop="imgCaptcha">
              <el-row :gutter="20">
                  <el-col :span="10">
                <span  style="display: inline-block;width: 130px;height: 38px;border: 1px solid #D7D7D7;"  >
          <img :src="imgCode"  style="width: 100%;height: 100%;cursor: pointer;" />
              </span>
              </el-col>
              <el-col :span="13">
                <el-input v-model="formData.imgCaptcha" type="text" placeholder="请输入图片验证码"></el-input>
                </el-col>
          </el-row>
          </el-form-item> -->
      <el-form-item prop="password">
        <el-input type="password" v-model="formData.password" prefix-icon="iconfont icon-password" placeholder="密码"></el-input>
      </el-form-item>
    </el-form>
    <!-- 短信登录 -->
    <el-form ref="thirdForm" v-else :model="formData" :rules="rules">
      <el-form-item prop="account">
        <div class="dxdl">
          <el-input prefix-icon="iconfont icon-phone" v-model="formData.account" placeholder="手机号码"></el-input>
        </div>
      </el-form-item>
      <el-form-item v-show="showRegisterPwd" prop="password">
        <el-input type="password" v-model="formData.password" placeholder="密码" prefix-icon="iconfont icon-password"></el-input>
      </el-form-item>
      <el-form-item v-show="showRegisterWx" prop="wx">
        <el-input type="text" prefix-icon="iconfont icon-weixin" v-model="formData.wx" maxlength="19" placeholder="微信(选填)"></el-input>
      </el-form-item>
      <el-form-item v-show="showRegisterQq" prop="qq">
        <el-input type="text" prefix-icon="iconfont icon-qq" v-model="formData.qq" placeholder="QQ(选填)"></el-input>
      </el-form-item>

      <el-form-item prop="code">
        <div class="dxdl">
          <el-input class="dxdl-code-left" wdith:100px prefix-icon="iconfont icon-yanzhengma" v-model="formData.code" placeholder="请输入验证码"></el-input>
          <el-button class="dxdl-code-right" @click="verfiy">{{ codeBtnText }}</el-button>
        </div>
      </el-form-item>
      <!-- <el-form-item prop="code" v-model="formData.option"> </el-form-item> -->
      <!-- @click="obj.validate()" -->
      <!-- <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm" >
    <el-dialog     title="图片验证码"
        :visible.sync="dialogVisible" width="35%" :before-close="handleClose">
          <el-form-item prop="imgCaptcha"   >
              <el-row :gutter="20">
                  <el-col :span="10">
                <span  style="display: inline-block;width: 130px;height: 38px;border: 1px solid #D7D7D7;"  >
          <img :src="imgCode"  style="width: 100%;height: 100%;cursor: pointer;" @click="() => {imgCode2()}"/>
              </span>
              </el-col>
              <el-col :span="13">
                <el-input v-model="formData.imgCaptcha" type="text" placeholder="请输入图片验证码"></el-input>
                </el-col>
          </el-row>
          </el-form-item>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button   type="primary" @click="dialogVisible = false, onSubmit(formData.imgCaptcha)">确 定</el-button>
        </span>
    </el-dialog>
    </el-form> -->
      <!--  -->
    </el-form>
  </div>
</template>
<script>
// let obj = {}
import { captchaCode, getSitepassword, checkCap } from '../../network/api'
// eslint-disable-next-line no-unused-vars
const _self = this
export default {
  props: {
    numOrSms: { default: true }, // 账号input为true,短信input为false
    showRegisterWx: { default: false }, // 注册页短信栏需要显示输入密码
    showRegisterQq: { default: false }, // 注册页短信栏需要显示输入密码
    showRegisterPwd: { default: false } // 注册页短信栏需要显示输入密码
  },
  data () {
    // 密码验证自定义
    var validatePass = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入密码'))
      } else {
        if (!/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/.test(value)) {
          callback(new Error('由数字和字母组成,长度在6到16位之间'))
        }
        callback()
      }
    }
    // var validateWx = (rule, value, callback) => {
    //   if (!value) {
    //     callback(new Error('请输入微信号'))
    //   } else {
    //     // if (!/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/.test(value)) {
    //     // if (!/^[a-zA-Z]{1}[-_a-zA-Z0-9]{5,19}$/.test(value)) { // 原始微信号匹配
    //     // if (/^{}&/.test(value)) {
    //     // callback(new Error('微信号由数字1开头 是11位手机号码'))
    //     // } // ^[a-zA-Z]{1}[-_a-zA-Z0-9]{5,19}+$
    //     callback()
    //   }
    // }
    // var validateQq = (rule, value, callback) => {
    //   if (!value) {
    //     callback(new Error('请输入QQ'))
    //   } else {
    //     // [1-9][0-9]{5,11}
    //     if (!/^[\d]{5,11}$/.test(value)) {
    //       callback(new Error('由数字开头 长度在5到11位之间'))
    //     }
    //     callback()
    //   }
    // }

    return {
      formData: {
        account: '',
        password: '',
        // imgCaptcha: '',
        wx: null,
        qq: null,
        code: '',
        accountBoole: null,
        passwordBoole: null,
        wxBoole: null,
        qqBoole: null,
        codeBoole: null
        // option: null
      },
      ruleForm: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      imgCode: '',
      obj: '',
      dialogVisible: false,
      codeFlag: true,
      timer: null,
      codeBtnText: '获取验证码',
      vid: '',
      rules: {
        account: [
          { required: true, message: '请输入手机号码', trigger: 'change' },
          {
            pattern: /(^1[0-9]{10}$)/,
            message: '请输入正确的手机号码',
            trigger: 'change'
          }
        ],
        password: [{ validator: validatePass, trigger: 'change' }],
        // wx: [{ required: false, validator: validateWx, trigger: 'change' }],
        // qq: [{ required: false, validator: validateQq, trigger: 'change' }],
        code: [{ required: false, message: '请输入手机验证码', trigger: 'change' }],
        imgCaptcha: [{ required: false, message: '请输入四位数验证码 ', trigger: 'change' }]
      }
    }
  },
  created () {
    this.getvid()
    // _this = this
    // this.verfiy()
  },
  methods: {
    showId () {
      // console.log(this.$route.query.user_id)
    },
    onSubmit (imgCaptcha) {
      // console.log(imgCaptcha.toString())
      // 发送提交
      captchaCode(imgCaptcha.toString()).then((res) => {
        this.formData.imgCaptcha = ''
        if (res.code === '') {
          this.$notify.warning({ title: '验证码不能为空', message: res.msg })
        } else if (res.code === 1) {
          this.$notify({ message: res.msg, type: 'success' })
          // this.getCode()
        } else if (res.code === 0) {
          this.$notify.error({ title: '验证码输入有误', message: res.msg })
        }
      })
    },
    handleClose (done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          done()
        })
        .catch((_) => {})
    },
    getvid () {},
    // load () {
    verfiy () {
      const _this = this
      // eslint-disable-next-line prefer-const
      let mobile = this.formData.account
      getSitepassword(mobile).then((res) => {
        // window.vaptcha({ vid: res.data.vaptcha_key, type: 'invisible' }) // scene: 0 // 场景值 默认0
        // .then(function (vaptchaObj) {
        // _this.obj = vaptchaObj // 将 VAPTCHA 验证实例保存到局部变量中
        // 验证码加载完成后将事件绑定到按钮
        // vaptchaObj.listen('pass', function () {
        // const serverToken = vaptchaObj.getServerToken()
        // 验证成功进行后续操作, serverToken.server, serverToken.token)
        const mobile = JSON.parse(localStorage.getItem('reg_account'))
        const pathname = window.location.pathname
        checkCap(mobile, pathname === '/register' ? 'register' : 'mobilelogin')
          .then((res) => {
            if (res.code === 1) {
              _this.$notify.success({ title: '短信发送成功', message: res.msg })
              _this.timmer()
              // vaptchaObj.reset() // 重置 VAPTCHA
            } else {
              _this.$message({ message: res.msg, type: 'error' })
              // vaptchaObj.reset() // 重置 VAPTCHA
            }
          })
          .catch((err) => {
            console.log(err)
          })
        // 验证成功进行后续操作 checkCap
      })
      // 关闭验证弹窗时触发
      // vaptchaObj.listen('close', function () {
      //   vaptchaObj.reset() // 重置 VAPTCHA
      // })
      // })
      // })
      // }
    },
    // 获取验证码:需要先向父组件发送一个事件 只能由父组件来完成
    timmer () {
      let time = 60
      if (!this.timer) {
        this.timer = setInterval(() => {
          if (time > 0) {
            this.codeFlag = false
            this.codeBtnText = time + 's后重新发送'
            time--
          } else {
            clearInterval(this.timer)
            this.codeFlag = true
            this.codeBtnText = '获取验证码'
            this.timer = null
          }
        }, 1000)
      }
      this.$emit('get-code')
    },
    // 获取验证码:需要先向父组件发送一个事件 只能由父组件来完成
    getCode () {
      let time = 60
      if (!this.timer) {
        this.timer = setInterval(() => {
          if (time > 0) {
            this.codeFlag = false
            this.codeBtnText = time + 's后重新发送'
            time--
          } else {
            clearInterval(this.timer)
            this.codeFlag = true
            this.codeBtnText = '获取验证码'
            this.timer = null
          }
        }, 1000)
      }
      this.$emit('get-code')
    }
  },
  mounted () {
    // _this = this
    // this.verfiy()
    // this.obj.validate()
    // this.load()
  },
  watch: {
    // 监听表单数据并传递给父组件
    formData: {
      handler (newData) {
        // 判断验证真假
        this.$refs.thirdForm.validateField('account', (valid) => {
          if (valid) {
            this.formData.accountBoole = false
          } else {
            // eslint-disable-next-line camelcase
            const reg_account = this.formData.account
            // this.$store.dispatch('reg_account', reg_account)
            localStorage.setItem('reg_account', reg_account)
            // eslint-disable-next-line no-undef
            // const reg_account = this.formData.account
            // this.$store.dispatch('reg_account', this.formData.account)
            // console.log(this.formData.account)
            // eslint-disable-next-line no-undef
            // console.log(JSON.parse(localStorage.getItem('reg_account')))
            // console.log(this.$store.getters.reg_account)
            this.formData.accountBoole = true
          }
        })
        this.$refs.thirdForm.validateField('password', (valid) => {
          if (valid) {
            this.formData.passwordBoole = false
          } else {
            this.formData.passwordBoole = true
          }
        })
        this.$refs.thirdForm.validateField('wx', (valid) => {
          if (valid) {
            this.formData.wxBoole = false
          } else {
            this.formData.wxBoole = true
          }
        })
        this.$refs.thirdForm.validateField('qq', (valid) => {
          if (valid) {
            this.formData.qqBoole = false
          } else {
            this.formData.qqBoole = true
          }
        })
        this.$refs.thirdForm.validateField('code', (valid) => {
          if (valid) {
            this.formData.codeBoole = false
          } else {
            this.formData.codeBoole = true
          }
        })
        // 向父组件发送事件传递数据
        this.$emit('form-data', newData)
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.dxdl {
  display: flex;
  flex-direction: row;
}
.dxdl-num-left {
  margin-right: -1px;
  width: 80px;
}
.dxdl-num-left input.el-input__inner,
.dxdl-code-left input.el-input__inner {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.dxdl-num-right input.el-input__inner,
.dxdl-code-right.el-button {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.dxdl-code-right.el-button {
  border-left: none;
}
/* --- */
/* --- */
</style>
